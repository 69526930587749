<template>
  <div style='margin-bottom:100px;'>
    <el-card class="box-card" v-loading="loading">
				<div slot="header" class="clearfix"><span>出货奖励规则</span></div>
        <el-form :model="ruleForm"  :rules="rules"  ref="ruleForm" label-width="150px">

          	<el-form-item label="推手出货奖励配置：">
              <el-radio v-model="ruleForm.IsOpenAgentDeliveryRewardEntrance" :label="true">开启</el-radio>
              <el-radio v-model="ruleForm.IsOpenAgentDeliveryRewardEntrance" :label="false">关闭</el-radio>
              
              <div class='entrance-btn' >开启后，推手个人中心将展示出货收益，推手可查看出货收益明细，<el-button type='text'  @click='rewardEntranceVisible=true'>查看示例</el-button></div>
            </el-form-item>

            <el-form-item label="出货奖励梯度：">

              <div class='reward-btn' @click='rewardIntroVisible=true'>点击查看出货奖励说明</div>
              
              <el-table :data="ruleForm.DeliveryRewardGradientList" style="width: 100%;">
                <el-table-column label="梯度">
                   <template slot-scope="scope">
                      第{{scope.$index+1}}梯度
                  </template>
                </el-table-column>
                <el-table-column label="团队订单数量" width="300px">
                  <template slot-scope="scope">
                    <span>团队订单>= </span>
                    <el-form-item inline-message :prop="'DeliveryRewardGradientList.' +scope.$index + '.MinTeamOrderCount'"	:rules='rules.MinTeamOrderCount' style="display:inline-block;margin:20px 0;height:50px;">
                      <el-input style='width:170px;margin: 0 5px' v-model='scope.row.MinTeamOrderCount'  @input='scope.row.MinTeamOrderCount=onlyNumber1(scope.row.MinTeamOrderCount,scope.$index)'></el-input>
                    </el-form-item>
                    <span>单</span>
                  </template>
                </el-table-column>
                <el-table-column label="梯度奖励金" width="300px">
                  <template slot-scope="scope">
                    <el-form-item inline-message :prop="'DeliveryRewardGradientList.' +scope.$index + '.EverySinglePrice'"	:rules='rules.EverySinglePrice' style="display:inline-block;margin:20px 0;height:50px;">
                      <el-input  style='width:170px;margin-right:5px' v-model='scope.row.EverySinglePrice' @input='scope.row.EverySinglePrice=onlyNumber(scope.row.EverySinglePrice,scope.$index)'></el-input>
                    </el-form-item>
                    <span>元/单</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button type="text" style='color: #F56C56' @click='deleteGradient(scope.$index)'>删除</el-button>
                  </template>
                </el-table-column>
              </el-table>

              <el-button v-show='ruleForm.DeliveryRewardGradientList.length<25' type="text" style="margin-top: 10px;" @click='addGradient'>添加梯度</el-button>
            </el-form-item>
        </el-form>
    </el-card>

    <el-card class="box-card footer">
			<el-button style="width:240px;position: relative;bottom: 20px;right: 190px;" type="primary" @click="saveSupiler('ruleForm')">保存</el-button>
		</el-card>
    
    <el-dialog
      :visible.sync="rewardEntranceVisible"
      width="500px"
      class='reward-intro'
      >
      <div style='margin: 0 auto;text-align: center;padding-bottom:10px;'>
        <img src='http://cdn.dkycn.cn/images/melyshop/reward-entrance-img.png'>
      </div>
    </el-dialog>

    <el-dialog
      title="出货奖励说明"
      :visible.sync="rewardIntroVisible"
      width="500px"
      class='reward-intro'
      >
      <div>
        <p>1.根据团队出货订单计算出货奖励；可配置不同梯度，最多添加25个梯度</p>
        <p>2.团队出货订单=推手销售订单+推手团队下级销售订单</p>
        <p>3.根据订单创建时，推手所属的团队计入</p>
        <p>4.订单内包含计入出货奖励的商品，且商品未全额退款或全部退款，且确认收货的订单，计入梯度数量</p>
        <p>5.可根据商品配置是否计入出货奖励核算，<el-button type='text' @click='openWindowSet'>点击进行配置</el-button></p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rewardIntroVisible = false">关闭</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
	import apiList from '@/api/other.js'
export default {
  data() {
    var checkMinTeamOrderCount = (rule, value, callback) => {
      let index = Number( rule.fullField.split(".")[1])
      let lastLevel = this.ruleForm.DeliveryRewardGradientList[(index-1)]? this.ruleForm.DeliveryRewardGradientList[(index-1)].MinTeamOrderCount : 0
      let nextLevel = this.ruleForm.DeliveryRewardGradientList[(index+1)]? this.ruleForm.DeliveryRewardGradientList[(index+1)].MinTeamOrderCount : 0
      if(Number(value)==0||Number(value)<0||value==''||value==null||!value){
        return callback(new Error('请输入订单数量'));
      }
      if(index&&lastLevel&&Number(value)<=Number(lastLevel)){
        return callback(new Error('订单数量需高于上一梯度'));
      }
      if (nextLevel&&Number(value)>=Number(nextLevel)){
        return callback(new Error('订单数量需低于下一梯度'));
      }
      if (Number(value)<1||Number(value)>9999999){
        return callback(new Error('订单数量请设置在1~9999999之间，整数'));
      }
      return callback()
    };
    var checkEverySinglePrice = (rule, value, callback) => { 
      if(Number(value)==0||Number(value)<0||value==''||value==null||!value){
        return callback(new Error('请输入梯度奖励金'));
      }else if (Number(value)<0.01||Number(value)>9999){
        return callback(new Error('励金请设置在0.01~9999之间，最多2位小数'));
      }else {
        return callback()
      }
    };
    return {
      loading: false,
      ruleForm: {
        IsOpenAgentDeliveryRewardEntrance: true,
        DeliveryRewardGradientList: []
      },
      rules: {
        MinTeamOrderCount: [{
          required: true,
          trigger: 'blur',
          validator: checkMinTeamOrderCount
        }],
        EverySinglePrice: [{
          required: true,
          trigger: 'blur',
          validator: checkEverySinglePrice
        }],
      },
      rewardEntranceVisible: false,
      rewardIntroVisible: false,
      maxGradient: 0,
      saveFlag: false
    }
  },
  beforeMount() {
    this.getData()
  },
  methods: {
    onlyNumber1(obj,index) {
      let lastIndex = index-1
      let nextIndex = index+1
      let list = this.ruleForm.DeliveryRewardGradientList
      if(lastIndex>= 0&& list[lastIndex].MinTeamOrderCount!= null){
        this.$refs['ruleForm'].validateField('DeliveryRewardGradientList.' +lastIndex + '.MinTeamOrderCount')
      }
      if(nextIndex< list.length && list[nextIndex].MinTeamOrderCount!= null){
        this.$refs['ruleForm'].validateField('DeliveryRewardGradientList.' +nextIndex + '.MinTeamOrderCount')
      }

      obj = obj.replace(/^0*(0\.|[1-9])/, '$1');
      obj = obj.replace(/[^\d]/g, "");
      return  obj
   },
   onlyNumber(obj,index) {
      if (obj != '' && obj.substr(0, 1) == '.') {obj = ""; }
      obj = obj.replace(/^0*(0\.|[1-9])/, '$1'); 
      obj = obj.replace(/[^\d.]/g, ""); 
      obj = obj.replace(/\.{2,}/g, "."); 
      obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
      obj = obj.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') 
      if (obj.indexOf(".") < 0 && obj != "") {
        if (obj.substr(0, 1) == '0' && obj.length == 2) {
          obj = obj.substr(1, obj.length);
        }
      }
      return  obj
   },
    saveSupiler(ruleForm){
        this.$refs[ruleForm].validate( (valid) => {
          if (valid) {
            if(!this.saveFlag){
              this.saveFlag = true

              this.saveInfo()

              setTimeout(()=>{
                this.saveFlag = false
              },2000)
            }
          }else {
						this.$common.completeFormInformation('请完善梯度信息','is-error')
          }
        })
    },
    async saveInfo(){
      this.loading = true
      this.ruleForm.DeliveryRewardGradientList.map((t,i)=>{
        t.GradientNum = i+1
        return t
      })
      try{
        let result = await apiList.agentDeliveryConfigSave(this.ruleForm);
        this.$message({
          showClose: true,
          type: 'success',
          message: '操作成功'
        });
        this.loading = false
      } catch (error) {
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    async getData() {
      this.loading = true
      try {
        let result = await apiList.agentDeliveryConfigIndex()
        this.ruleForm = result.Result
      } catch (e) {
        //TODO handle the exception
      } finally {
        
        this.loading = false
      }
      this.loading = false
    },
    openWindowSet(){
      let url = '/goods/goodsList'
      this.$common.openNewWindow(url)
    },
    addGradient(){
      let item = {
        MinTeamOrderCount: null,
        EverySinglePrice: null
      }
      this.ruleForm.DeliveryRewardGradientList.push(item)
    },
    deleteGradient(index){
      this.ruleForm.DeliveryRewardGradientList.splice(index,1)
    }
  }
}
</script>
<style lang="less" scoped>
.footer {
  background-color: #fff;
  width: 100%;
  margin-right: 30px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  position: fixed;
  bottom: 0px;
  z-index: 10;
}
.reward-btn{
  color: #909399;
  cursor: pointer;
  text-decoration: underline;
}
.entrance-btn{
  color: #909399;
}
.reward-intro{
  ::v-deep .el-dialog__body {
    padding: 0 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
}
</style>