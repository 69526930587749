<template>
	<div style="background: #fff;padding: 15px;">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="奖励结算" name="1"></el-tab-pane>
			<el-tab-pane label="奖励明细" name="2"></el-tab-pane>
			<el-tab-pane label="奖励规则" name="3"></el-tab-pane>
		</el-tabs>
		<div v-if="activeName==1">
			<div class="filter-container">
				<div class="filter-item">
						<label class="label" style="white-space: nowrap;font-weight: 700;">关键字: </label>
						<el-input v-model="searchKey" placeholder="手机号、微信昵称、姓名" style="width:200px;margin-right:10px"></el-input>
						
						<label class="label" style="width: 70px;white-space: nowrap;">日期: </label>
						<el-date-picker v-model="statisTime" type="month" format="yyyy-MM" value-format="yyyy-MM"></el-date-picker>
						
						<el-button type="primary" style="margin-left: 15px;width: 90px;" @click="goodsSearch">查询</el-button>
						<el-button style="margin-left: 15px;width: 90px;" @click="exportlist">导出</el-button>
				</div>
				
			</div>
			<!-- 门店管理表格 -->
			<div class="table-container">
				<el-table :data="tableList" style="width: 100%;" v-loading="loading" key="rewardtable">
					<el-table-column label="推手" width="270px" key="reward1">
						<template slot-scope="scope">
							<div style="display: flex;align-items: center;">
								<img class="table-img" :src="scope.row.WxHeadUrl ? scope.row.WxHeadUrl : defaultHeader" >
								<div style="margin-left: 10px;">
                  <div class="table-name">{{scope.row.NameDisplay}}</div>
                  <div>{{scope.row.Phone}}</div>
                </div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="AgentRoleName" label="代理级别" key="reward2"></el-table-column>
					<el-table-column prop="OrderCount" label="出货订单" key="reward3"></el-table-column>
					<el-table-column prop="AwardMoney" label="出货奖励（元）" key="reward4"></el-table-column>
					<el-table-column label="操作"  key="reward5">
						<template slot-scope="scope">
							<el-button type="text" @click="openDetile(scope.row.Phone)">出货明细</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div style="display:flex;justify-content:space-between;align-items:center;">
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
					</div>
					<el-pagination key="rewardpagination" v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
					 :total="Total">
					</el-pagination>
				</div>
			</div>
		</div>
		<div v-if="activeName==2">
			<div class="filter-container">
				<div class="filter-item">
						<label class="label" style="white-space: nowrap;font-weight: 700;">关键字: </label>
						<el-input v-model="searchKey" placeholder="手机号、微信昵称、姓名" style="width:200px;margin-right:10px"></el-input>
						
						<label class="label" style="width: 70px;white-space: nowrap;">日期: </label>
						<el-date-picker v-model="statisTime" type="month" format="yyyy-MM" value-format="yyyy-MM-dd"></el-date-picker>

						<el-button type="primary" style="margin-left: 15px;width: 90px;" @click="goodsSearch">查询</el-button>
						<el-button style="margin-left: 15px;width: 90px;" @click="exportlist2">导出</el-button>
				</div>
			</div>
			<!-- 门店管理表格 -->
			<div class="table-container">
				<el-table :data="tableList2" style="width: 100%;" v-loading="loading" key="detailtable">
					<el-table-column label="推手" width="220px"  key="detail1">
						<template slot-scope="scope">
							<div style="align-items: center;">
                <div class="table-name">{{scope.row.NameDisplay}}</div>
                <div>{{scope.row.Phone}}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="AgentRoleName" label="推手级别" key="detail2"></el-table-column>
					<el-table-column label="订单编号" width="210" key="detail3">
            <template slot-scope="scope">
              <el-button type='text' @click='openWindowOrder(scope.row.OrderNo)'>{{scope.row.OrderNo}}</el-button>
            </template>
          </el-table-column>
					<el-table-column prop="AgentNameDisplay" label="出货推手" key="detail4"></el-table-column>
					<el-table-column prop="MemberAgentRoleName" label="出货时等级" key="detail5"></el-table-column>
          <el-table-column prop="CompleteTime" label="完成时间" key="detail6"></el-table-column>
          <el-table-column prop="PayMoney" label="支付金额" key="detail7"></el-table-column>
				</el-table>
				<div style="display:flex;justify-content:space-between;align-items:center;">
					<div></div>
					<el-pagination key="detailpagination" v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
					 :total="Total">
					</el-pagination>
				</div>
			</div>
		</div>
		<div v-if="activeName==3">
			<reward-rules></reward-rules>
		</div>

	</div>
</template>

<script>
	import config from '@/config/index'
	import apiList from '@/api/other.js'
	import rewardRules from './rewardRules.vue'
	export default {
		components: {
			rewardRules
		},
		data() {
			return {
				activeName:'1',


				loading: false,
				searchKey: '',
				statisTime: '',
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				tableList: [],
				imgUrl: config.IMG_BASE,
				defaultHeader:config.DEFAULT_HEADER,
				exportUrl: config.EXPORT_URL,

				tableList2:[]

			};
		},
		beforeMount() {
			this.initTime()
			this.getListData()
		},
		methods: {
			handleClick(e){
				this.activeName = e.name
				this.searchKey = ''
				this.statisTime = ''
				this.currentPage = 1
				this.pageSize = 20
				this.Total = 0
				this.initTime()
				this.getlist()
				// console.log(this.activeName)
			},
			initTime(){
				let date = new Date();
				let year = date.getFullYear();
				let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
				this.statisTime = year + "-" + month
			},
			goodsSearch() {
				this.currentPage = 1
				this.getlist()
			},
			getlist(){
				// console.log(this.activeName)
				if(this.activeName==1){
					this.getListData()
				}else if(this.activeName==2){
					this.getListData2()
				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getlist()
			},
			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getlist()
			},

			/*
				出货明细
			*/
			async getListData2() {
				this.loading = true
				try {
					let data = {
						Skip: (this.currentPage - 1) * this.pageSize,
						Take: this.pageSize, // 取的数据
						Keywords: this.searchKey,
						StatisTime: this.statisTime
					}
					let result = await apiList.agentSellAwardDetail(data)

					this.tableList2 = result.Result.Results
					// console.log(this.tableList2,2)
					this.Total = result.Result.Total
				} catch (e) {
					//TODO handle the exception
				}
				this.loading = false
			},
			async exportlist2(){
				this.loading = true;
				try {
					let url = this.exportUrl + '/pc/agent-sell-award/detail-export?' +
						'&Keywords=' + this.searchKey +
						'&StatisTime=' + ((this.statisTime == null) ? '' : this.statisTime)
					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}
			},
			openWindowOrder(orderNo){
        let url = '/order/orderDetailpage?Id=' + orderNo
				this.$common.openNewWindow(url)
      },

			/*
				出货奖励
			*/
			// awardMoneys
			async getListData() {
				this.loading = true
				try {
					let data = {
						Skip: (this.currentPage - 1) * this.pageSize,
						Take: this.pageSize, // 取的数据
						Keywords: this.searchKey,
						StatisTime: this.statisTime
					}
					let result = await apiList.agentSellAwardIndex(data)

					this.tableList = result.Result.Results
					// console.log(this.tableList,1)
					this.Total = result.Result.Total
				} catch (e) {
					//TODO handle the exception
				}
				this.loading = false
			},
			openDetile(phone){
        // let url = '/agent/sellRewardDetail?phone=' + phone
				// this.$common.openNewWindow(url)
				this.handleClick({name:'2'})
				this.searchKey = phone
			},
			async exportlist(){
				this.loading = true;
				try {

					let url = this.exportUrl + '/pc/agent-sell-award/export?' +
						'&Keywords=' + this.searchKey +
						'&StatisTime=' + (this.statisTime ? this.statisTime : '')
					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}
			},

		},
	}
</script>

<style lang="less" scoped>
.table-container{
  .table-img{
    width: 50px !important;
    object-fit: scale-down;
    border-radius: 100%;
  }
  .table-name{
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
